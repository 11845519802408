import React, { useState, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
// import Box from "@material-ui/core/Box";
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonRow,
  IonList,
  IonButton,
  IonCheckbox,
  IonTextarea,
  IonCard,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";

const ItemRendererDyn = ({ item, indexno, setResponseData, responseData }) => {

  window.scrollTo(0, 0);

  const [response, setResponse] = useState(
    responseData ? responseData[indexno].response : null
  );
  const [value, setValue] = useState(
    responseData ? responseData[indexno].response : null
  );
  const [cardState, setCardState] = useState();
  const [buttState, setButtState] = useState(
    responseData ? responseData[indexno].response : null
  );
  const [hidden, setHidden] = useState(false);

  const checkboxHandler = (e) => {
    if (e.detail.checked) {
      let tempArr = [...responseData];
      tempArr[indexno].response = [
        ...tempArr[indexno].response,
        e.detail.value,
      ];
      tempArr[indexno].response = [...new Set(tempArr[indexno].response)];

      setResponseData(tempArr);
    } else {
      let tempArr = [...responseData];
      tempArr[indexno].response = tempArr[indexno].response.filter(
        (el) => el !== e.detail.value
      );
      setResponseData(tempArr);
    }
  };

  const responseHandler = (e) => {
    if (item.type === "textbox") {
      setResponse(e.detail.value);

      let tempArr = [...responseData];
      tempArr[indexno].response = e.detail.value;

      setResponseData(tempArr);

      return;
    }
    if (item.type === "boolean") {
      setButtState(e.target.id);

      let tempArr = [...responseData];
      tempArr[indexno].response = e.target.id;

      setResponseData(tempArr);
      return;
    }

    if (item.type === "dropdown") {
      setResponse(e.detail.value);

      let tempArr = [...responseData];
      tempArr[indexno].response = e.detail.value;

      setResponseData(tempArr);

      return;
    }

    if (item.type === "listsingleselect") {
      setResponse(e.detail.value);

      let tempArr = [...responseData];
      tempArr[indexno].response = e.detail.value;

      setResponseData(tempArr);

      return;
    }

    if (item.type === "cards") {
      setCardState(e);
      setResponseData({
        ...responseData,
        [item.title]: e,
      });
      return;
    }
    if (item.type === "rating5" || item.type === "rating10") {
      setValue(e);
      let tempArr = [...responseData];
      tempArr[indexno].response = e;

      setResponseData(tempArr);
      return;
    }

    setResponse(e.detail.value);

    if (item.type === "checkbox") {
      checkboxHandler(e);
      return;
    }
  };

  function createMarkup(content) {
    return { __html: content };
  }

  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  useEffect(() => {
    if (item.displaycondition.questionid && responseData) {
      console.log(item);
      const filteredArray = responseData.filter(
        (value) =>
          item.displaycondition.answers.includes(
            value.response && value.response.toString()
          ) && value.elementid === item.displaycondition.questionid
      );
      if (filteredArray.length) {
        setHidden(false);
      } else {
        setHidden(true);
      }
    }
  }, [item, responseData]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (item.required) {
      let tempArr = [...responseData];
      tempArr[indexno].required = true;
      setResponseData(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    hidden === false && (
      <>
        <IonRow style={{display: 'flex', justifyContent: 'center'}}>
          {item.type === "dropdown" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              <IonItem style={{ width: "50%" }}>
                <IonSelect
                  className="selectCss"
                  style={{ fontFamily: "Open Sans" }}
                  placeholder="bitte wählen"
                  interfaceOptions={customAlertOptions}
                  interface="popover"
                  value={response}
                  okText="Ok"
                  cancelText="Cancel"
                  onIonChange={(e) => responseHandler(e)}
                >
                  {item.choices.map((el, ind) => (
                    <IonSelectOption value={el} key={ind}>
                      <div style={{ fontFamily: "Open Sans", fontWeight: 600 }}>
                        {el}
                      </div>
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </>
          )}
          {item.type === "listsingleselect" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              <IonItem style={{ width: "50%" }}>
                <IonRadioGroup
                  value={response}
                  onIonChange={(e) => responseHandler(e)}
                >
                  {item.choices.map((e, ind) => (
                    <IonItem lines="none" key={ind}>
                      <IonLabel>{e}</IonLabel>
                      <IonRadio slot="start" value={e} />
                    </IonItem>
                  ))}
                </IonRadioGroup>
              </IonItem>
            </>
          )}
          {item.type === "cards" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              <div style={{ width: "50%" }}>
                <IonGrid>
                  <IonRow style={{display: 'flex', justifyContent: 'center'}}>
                    {item &&
                      item.choices.map((el, ind) => (
                        <IonCol key={ind} size="6">
                          <IonCard
                            className="ion-no-margin"
                            style={
                              cardState === el
                                ? {
                                    background: `grey linear-gradient(
                      rgba(255, 255, 255, 0.70),
                      rgba(255, 255, 255, 0.70)
                    )`,
                                    height: "100%",
                                    display: "flex",
                                  }
                                : { height: "100%", display: "flex" }
                            }
                            button
                            onClick={() => responseHandler(el)}
                          >
                            <div className="centerCenter">
                              <img
                                src={el.image}
                                alt={el.text}
                                className="boximage"
                              />
                            </div>
                            <IonCardContent
                              style={{
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                fontSize: "15px",
                                color: "black",
                              }}
                            >
                              {el.text}
                            </IonCardContent>
                          </IonCard>
                        </IonCol>
                      ))}
                  </IonRow>
                </IonGrid>
              </div>
            </>
          )}
          {item.type === "boolean" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              <div style={{ width: "50%" }}>
                <IonGrid>
                  <IonRow style={{display: 'flex', justifyContent: 'center'}}>
                    <IonCol size="6">
                      <IonButton
                        expand="block"
                        size="medium"
                        color={buttState === "ja" ? "medium" : "light"}
                        id="ja"
                        onClick={(e) => responseHandler(e)}
                        strong
                      >
                        Ja
                      </IonButton>
                    </IonCol>
                    <IonCol size="6">
                      <IonButton
                        expand="block"
                        strong
                        size="medium"
                        color={buttState === "nein" ? "medium" : "light"}
                        id="nein"
                        onClick={(e) => responseHandler(e)}
                      >
                        Nein
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </>
          )}
          {item.type === "checkbox" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                  width: '100%',
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>
              <IonList style={{ width: "50%" }}>
                {item.choices.map((e, i) => (
                  <IonItem lines="none" key={i}>
                    <IonLabel>{e}</IonLabel>
                    <IonCheckbox
                      checked={responseData[indexno].response.includes(e)}
                      style={{ fontFamily: "Open Sans" }}
                      onIonChange={(e) => responseHandler(e)}
                      slot="end"
                      value={e}
                    />
                  </IonItem>
                ))}
              </IonList>
            </>
          )}
          {item.type === "rating5" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              {/* <div className="ratingwrapper"> */}

              <div style={{ width: "50%" }}>
                <IonGrid>
                <IonRow style={{display: 'flex', justifyContent: 'center'}}>
              <div>{item.left ? item.left : ""}</div>
                <div style={{margin: '0px 20px'}}>   <Rating
                  size="large"
                  name={item.title}
                  value={value}
                  precision={1}
                  onChange={(_event, e) => {
                    responseHandler(e);
                  }}
                /></div>
                <div>{item.right ? item.right : ""}</div>
</IonRow>
                </IonGrid>
              </div>

              




            
               
                {/* </div> */}
             
            </>
          )}
          {item.type === "rating10" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>

              <div style={{ width: "50%" }}>
                <IonGrid>
                <IonRow style={{display: 'flex', justifyContent: 'center'}}>
              <div>{item.left ? item.left : ""}</div>
                <div style={{margin: '0px 20px'}}>   
                <Rating
                  max={10}
                  size="medium"
                  name={item.title}
                  value={value}
                  precision={1}
                  onChange={(event, e) => {
                    responseHandler(e);
                  }}
                /></div>
                <div>{item.right ? item.right : ""}</div>
</IonRow>
                </IonGrid>
              </div>
            </>
          )}
          {item.type === "textbox" && (
            <>
              <IonLabel
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  margin: "10px",
                   width: "100%",
                  textAlign: 'center',
color: item.required ? '#008000' : ''
                }}
              >
                {item.question} {item.required ? <i>(Pflichtfrage)</i> : null}
              </IonLabel>
              <IonItem style={{ width: "50%" }} lines="none">
                <IonTextarea
                  style={{
                    fontFamily: "Open Sans",
                    border: '1px solid #ebecf0',
                    paddingLeft: '10px',
                    marginBottom: '5px',
                  }}
                  placeholder={item.title}
                  // autofocus
                  rows={item.size}
                  cols={20}
                  value={response}                  
                  onIonChange={(e) => responseHandler(e)}
                ></IonTextarea>
              </IonItem>
            </>
          )}
          {item.type === "textblock" && (
            <div
              dangerouslySetInnerHTML={createMarkup(item.question)}
              style={{ fontFamily: "Open Sans" }}
              className="textBlock"
            ></div>
          )}
        </IonRow>
      </>
    )
  );
};

export default ItemRendererDyn;
