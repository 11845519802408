import React from "react";
import {
  IonRow,
  IonButton,
  IonCol,
  IonFooter,
  IonToolbar,
  IonIcon,
} from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";

const Footer = ({
  pageData,
  handleSubmit,
  //  footerStatus
}) => {
  return (
    <IonFooter>
      <IonToolbar className="toolheader">
        <IonRow style={{ padding: "5px" }}>
          {/* {pageData && pageData.index === 11 ? (
            <IonCol size="12" className="centerCenter">
              <IonButton color="primary" onClick={() => handleSubmit()}>
                Start Over
              </IonButton>
            </IonCol>
          ) : ( */}
          <>
            <IonCol size="6" className="centerCenter">
              <IonButton
                color="primary"
                disabled={
                  (pageData && pageData.index === 0) ||
                  (pageData && pageData.index === 11)
                    ? true
                    : false
                }
                onClick={() => handleSubmit("prev")}
              >
                {" "}
                <IonIcon style={{ paddingRight: "5px" }} icon={arrowBack} />
                zurück
              </IonButton>
            </IonCol>
            <IonCol size="6" className="centerCenter">
              <IonButton
                disabled={pageData && pageData.index === 11}
                // disabled={footerStatus}
                color="primary"
                onClick={() => handleSubmit("next")}
              >
                {pageData && pageData.index === 10 ? "submit" : "weiter"}{" "}
                <IonIcon style={{ paddingLeft: "5px" }} icon={arrowForward} />
              </IonButton>
            </IonCol>
          </>
          {/* )} */}
        </IonRow>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
