import React from "react";
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home.jsx";
import Uksh from "./pages/Uksh.jsx";
import Charite from "./pages/Charite.jsx";
import SurveyRenderer from "./pages/SurveryRenderer.jsx";
import ShortCutRedirect from "./pages/shortcutredirect.jsx";
import VierNullVier from "./pages/404.jsx";
import VierNullVierZwo from "./pages/_404.jsx";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/charite/" component={Charite} />
        <Route path="/uksh/" component={Uksh} />
        <Route path="/" component={Home} exact={true} />
        <Route path="/dynamic/:surveyid/" component={SurveyRenderer} />
        <Route path="/url/" component={ShortCutRedirect} />
        <Route path="/static/404" component={VierNullVier} />
        <Route path="/static/_404" component={VierNullVierZwo} />


      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
