import React from "react";
import {
  IonGrid,
  IonRow,
  IonContent,
  IonPage,
  IonHeader,
  IonItem,
} from "@ionic/react";

import "./Home.css";

const VierNullVier = () => {
  return (
    <IonPage
      style={{
        background: `linear-gradient(
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)
  ),
  url(${"https://feedback.saint-online.de/images/home-splash.jpg"}) no-repeat center center / cover`,
      }}
    >
      <IonHeader className="ion-no-border"></IonHeader>
      <IonContent
      // style={{
      //   background: "white",
      // }}
      >
        <IonGrid>
          <IonRow>
            {/* <IonItem
              className="transbutton"
              style={{ paddingTop: "100px", width: "100%" }}
              routerLink="/uksh/"
            >
              <div
                style={{ width: "100%", margin: "15px" }}
                className="centerCenter"
              >
                <img
                  className="uksh-logo"
                  src={"https://feedback.saint-online.de/images/uksh-logo.png"}
                  alt="logo"
                />
              </div>
            </IonItem> */}
          </IonRow>
          <IonRow>
            {/* <IonItem
              className="transbutton"
              style={{ paddingTop: "50px", width: "100%" }}
              routerLink="/charite/"
            >
              <div style={{ width: "100%" }} className="centerCenter">
                <img
                  className="charite-logo"
                  src={
                    "https://feedback.saint-online.de/images/charite-logo.svg"
                  }
                  alt="logo"
                />
              </div>
            </IonItem> */}
          </IonRow>
          <IonRow>
          <IonItem
              className="transbutton"
              style={{ paddingTop: "50px", width: "100%" }}
            
            >
              <div style={{ width: "100%" }} className="centerCenter">
              <div className="info">
                Die von Ihnen aufgerufene Seite existiert nicht.<p/>
                Bei Fragen oder Interesse wenden Sie sich bitte an
                <div className="infodaten">info@solufind.de</div>
                oder
                <div className="infodaten">02104 - 2863695</div>
              </div>
              </div>
            </IonItem>
          </IonRow>
              </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default VierNullVier;
