import React from "react";
import {
  IonRow,
  IonButton,
  IonCol,
  IonFooter,
  IonToolbar,
  IonIcon,
  IonChip,
  IonLabel
} from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";

const FooterDyn = ({
  pageData,
  handleSubmit,
  pageready,
  language,
  firstLastPage,
  requiredQuestionCheck,
  impressum,
  datenschutz
  //  footerStatus
}) => {

  console.log(pageData)
  console.log("FOOTER language",language)
  console.log("FOOTER imp",impressum)
  console.log("FOOTER ds",datenschutz)

  return (
    <IonFooter>
      
      <IonToolbar className="toolheader">
        {( impressum !== null || datenschutz !== null ) ? <IonRow><IonCol text-center><div style={{textAlign: 'center'}}>
          { datenschutz ? <IonChip outline color="primary">
            <IonLabel ><a href={datenschutz} target="_blank" rel="noreferrer noopener">Datenschutzerklärung</a></IonLabel>
          </IonChip> : ''} { datenschutz && impressum ? "|" : ''} { impressum ? <IonChip outline color="primary">
            <IonLabel><a href={impressum} target="_blank" rel="noreferrer noopener">Impressum</a></IonLabel>
          </IonChip> : ''}</div></IonCol></IonRow> : ''}
        <IonRow style={{ padding: "5px" }}>
          {/* {pageData && pageData.index === 6 ? (
            <IonCol size="12" className="centerCenter">
              <IonButton color="medium" onClick={() => handleSubmit()}>
                Start Over
              </IonButton>
            </IonCol>
          ) : ( */}

          {firstLastPage !== "last" && (
            <>
              <IonCol size="6" className="centerCenter">
                <IonButton
                  color="medium"
                  disabled={
                    firstLastPage === "first" || firstLastPage === "last"
                  }
                  onClick={() => handleSubmit("prev")}
                >
                  <IonIcon style={{ paddingRight: "5px" }} icon={arrowBack} />
                  zurück
                </IonButton>
              </IonCol>
              <IonCol size="6" className="centerCenter">
                <IonButton
                  disabled={requiredQuestionCheck ? true : false}
                  color="medium"
                  onClick={() => handleSubmit("next")}
                >
                  {/* {pageData && pageData.index === 5 ? "absenden" : "weiter"} */}
                  Weiter
                  <IonIcon style={{ paddingLeft: "5px" }} icon={arrowForward} />
                </IonButton>
              </IonCol>
            </>
          )}

          {/* )} */}
        </IonRow>
        { requiredQuestionCheck ? <IonRow><IonCol><div style={{background: '#008000', color: 'white', textAlign: 'center'}}>Es wurden nicht alle Pflichtfragen ausgefüllt - Bitte prüfen</div></IonCol></IonRow> : '' }
      </IonToolbar>
    </IonFooter>
  );
};

export default FooterDyn;
